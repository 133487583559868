// Site color settings & box sizes
@import 'constants';

// Zinnia related CSS rules //
@import 'zinnia';


// Navigation menu related CSS rules //
@import 'navigation';

// Tre recodring form formatting
@import 'tree-form';

// Map  related CSS rules
@import 'map';

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic&subset=latin,latin-ext);

* { box-sizing: border-box; }

html, body {
    margin: 0; padding: 0;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: $font-size;
    color: $fontColor;
}

h1 {
    font-size: 24px;
    margin: 0;
    padding: 7px;
    background-color: #E8D9C2;
}

/* site-wise link rules */
a { text-decoration: none; }
a:link, a:visited { color: $activeMenu_txtColor; }
a:hover { color: $anchor_hover_txtColor; }

#topmenu {
    li a:link, li a:visited { color: $defaultMenu_txtColor; }
    li a:hover, li a:active { color: $activeMenu_txtColor }
}

#pageWrapper {
    background: $body_bgColor;
    min-height: 100%;
    height: auto !important;
    // height: 100%;
    margin: 0 auto -1*$footer_height;
    min-width: 360px;
}

    #pageHeader {
        background-color: $header_bgColor;
        // height: $header_height;
        max-height: $header_height;
        // position: relative;
    }

    #pageHeaderMap {
        background: $header_bgColor;
    }

    #pageBody {
        background-color: $body_bgColor;
        margin: 0 auto;
    }

    #pageFooter {
        min-height: $footer_height;
        background-color: $footer_bgColor;
        clear: both;
    }

    .push {
        height: $footer_height;
        clear: both;
    }

#contentWrapper {
    width: $wrapper_width;
    min-height: 100%;
    margin: 0 auto;
}

#pagePicture {
    background-color: rgba(121, 124, 128, 0.4);
    position: relative;
    top: - $navMenu_height;
}

.leftSideColumn {
    float: left;
    width: 65%;
}

.rightSideColumn {
    float:right;
    width: 35%;
}

.backgroundTwoCols {
    overflow: auto;
    background: #ddccb2; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RkY2NiMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY1JSIgc3RvcC1jb2xvcj0iI2RkY2NiMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY1JSIgc3RvcC1jb2xvcj0iI2RkY2NiMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY1JSIgc3RvcC1jb2xvcj0iI2ViZGVjYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlYmRlY2IiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left, #ddccb2 0%, #ddccb2 65%, #ddccb2 65%, #ebdecb 65%, #ebdecb 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#ddccb2), color-stop(65%,#ddccb2), color-stop(65%,#ddccb2), color-stop(65%,#ebdecb), color-stop(100%,#ebdecb)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #ddccb2 0%,#ddccb2 65%,#ddccb2 65%,#ebdecb 65%,#ebdecb 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #ddccb2 0%,#ddccb2 65%,#ddccb2 65%,#ebdecb 65%,#ebdecb 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #ddccb2 0%,#ddccb2 65%,#ddccb2 65%,#ebdecb 65%,#ebdecb 100%); /* IE10+ */
    background: linear-gradient(to right, #ddccb2 0%,#ddccb2 65%,#ddccb2 65%,#ebdecb 65%,#ebdecb 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ddccb2', endColorstr='#ebdecb',GradientType=1 ); /* IE6-8 */
}

.leftContent {
    background: $section_bgColor;
    padding: 0 15px;
    text-align: justify;
    img {
        max-width: 100%;
        // width: 100%;
        height: auto;
    }
    a { display: block; }
    p { margin-top: 0; }
}
.rightContent {
    background: $section_bgColor;
}

.leftFooter {
    // display: table-cell;
    // padding: 5px 0 0 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 5px 0;
    img {
        margin-bottom: 5px;
        max-height: 50px;
        width: auto;
        padding: 0 5px 0 0;
        &:last-child { padding: 0;}
    }
}

.rightFooter {
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0;
    margin-left: -2px;
    font-size: 12px;
    color: white;
    height: 100%;
    background: $footer_bgColor;
    a {
        color: white;
        font-size: 12px;
        display: inline-block;
        padding: 0 8px 0 0;
        text-align: center;
    }
    a:hover { color: $activeMenu_txtColor;}
}

// .footer td {
//     border: 0;
//     padding: 0;
// }

#col1 { height: 5px;}
// #row2 {
//     height: 40px;
//     td {
//         border-left: 2px solid gray;
//         padding-left: 20px;
//     }
// }
// #row3 { height: 5px;}

.pageTitle {
    height: 45px;
    // background: #DDCCB2;
    margin: 0;
}

.title {
    font-size: 1.6em;
    font-weight: bold;
    float: left;
    padding: 3px 150px 3px 40px;
    background: $form_bgColor;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.triangle-topleft {
   width: 0;
   height: 0;
   border-top: 42px solid $form_bgColor;
   border-right: 42px solid transparent;
   float: left;
}

#headerContent {
    position: relative;
    max-width: $wrapper_width;
    overflow-x: visible;
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0 auto;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);

    p { margin: 0 0 16px 0; }
}

#headerContentMap {
    position: relative;
    margin: 0 auto;
    padding: 75px 15px 15px 15px;
    width: $wrapper_width;
    top: 0;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    p {
        margin: 0;
        color: #fff;
    }
}

#showMoreLessControl {
    display: none;
    margin: 0;
    padding: 7px 10px;
    text-align: center;
    color: black;
    background: $fontColor;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}

#bodyContent {
    background: #DDCCB2;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
    width: $wrapper_width;
    margin: 0 auto;
    overflow: hidden; // cut the box-shadow at the bottom
}
@media screen and (max-width: 943px) {
    #bodyContent { width: auto; }
}

#bodyContentMap {
    position: relative; // needed by Google Map --> SearchBox
    background: #DDCCB2;
    box-sizing: border-box;
    width: $wrapper_width;
    margin: 0 auto; left: 0; right: 0;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
}

.footerContent {
    position: relative;
    background: $footer_bgColor;
    overflow: auto;
    width: $wrapper_width;
    margin: 0 auto; left: 0; right: 0;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
    .leftSideColumn { width: 100%; }
    .rightSideColumn { width: 100%; }
    #horizontalDivider {
        margin: 0 15px;
        border: 1px solid gray !important;
    }
}

#languageChooser {
    position: absolute;
    bottom: 0; right: -36px;
    padding: 6px; margin: 0;
    // width: 24px; height: $font-size*3;

    li {
        list-style: none;
        margin: 0; padding: 0;
        line-height: 100%;
    }

    li a {
        color: #777;
        font-weight: bold;
        font-size: 0.9em;
    }

    li.active a { color: #fff; }
}

.padding {
    padding: 20px 20px 20px 20px;
    img { max-width: 100%; }
}

.logo {
    max-height: 50px;
    width: auto;
    //padding: 0 30px 0 0;
    padding: 0 5px 0 0;
    &:last-child { padding: 0;}
}

.popupPanel {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px; margin-left: -150px;
    height: auto;
    padding: 10px;
    background-color: white;
    border: 1px solid #666;
    background: #E8D9C2;
    text-align:  center;
    border-radius: 3px;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    font-size: 0.9em;
}


.btn {
  font-size: 14px;
  box-shadow: 1px 1px 1px #AEA391;
  padding: 6px 25px;
  margin-top: 20px;
  border: 1px solid #8F8678;
  background: #AEA391;
  display: inline-block;

  &:hover {
    background: #998F7F;
    color: $activeMenu_txtColor;
    cursor: pointer;
  }
}

.btn.disabled,
.btn.disabled:hover {
    @extend .btn;
    background: #AEA391;
    cursor: default;
    color: gray;
}

.btn-middle {
    @extend .btn;
    padding: 3px 8px;
    font-size:  0.9em;
    margin: 5px 2px;
}

.btn-small {
    @extend .btn;
    padding: 1px 3px;
    font-size:  0.7em;
    margin: 5px 2px;
}

.btn-info {
    color: #df1825;
    background: #c5c6c7;
    &:hover {
        background: #939596;
        background-image: -webkit-linear-gradient(top, #939596, #b4b7b8);
        background-image: -moz-linear-gradient(top, #939596, #b4b7b8);
        background-image: -ms-linear-gradient(top, #939596, #b4b7b8);
        background-image: -o-linear-gradient(top, #939596, #b4b7b8);
        background-image: linear-gradient(to bottom, #939596, #b4b7b8);
    }
}

.btn-alert {
    @extend .btn;
    color: #A10F19!important;
}
.btn-nomargin {
    @extend .btn;
    margin: 0;
}


#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1000;
    top:0;
}

#registerTree,
#treeList,
#treeForm  {
    width: $wrapper_width;
    margin: 0 auto;
    padding: 20px;
}

.treeFilter {
    display: none;
    padding: 10px;
}

.rowEven {
        background: rgba(170, 170, 170, .1 );
}

.rowOdd {
        background: rgba(230, 230, 230, .3 );
}


// Site logo formatting
#siteLogo {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 10;
    img {
        display: block;
        margin: 0 auto;
        max-height: 170px;
        height: auto;
    }
}

#userRegForm {
    background: inherit;
    width: auto;
    padding: 5px;
    text-align: center;
    .register { text-align: center; color: $activeMenu_txtColor; padding: 0; margin: 0;}
    .userMsg { font-size: 0.8em; padding: 0; margin: 10px 0 0 0;}
}

.infoWindow {
    border-radius: 3px;
    p { margin: 3px 0; }
    .icon-more img:hover {
        -webkit-filter: invert(.8);
        filter: invert(.8);
    }
}

.registeredTrees,
.registeredUsers {
    position: relative;
    display: inline-block;
    width: 48%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 1em;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
    overflow: hidden;

    .small {
        display: block;
        margin: 0;
        font-size: 0.6em;
        color: $fontColor;
        min-height: 26px;
    }

    .big {
        margin: 0;
        font-size: 1.5em;
        color: $activeMenu_txtColor;
        line-height: 1;
    }

    .left { float: left;}
    .right { float: right;}

}


// ----------------------------------
// START - 'Partner page' formatting:
// ----------------------------------
div.partner {
    margin: 30px 0 30px 0;
    display: inline-block; // prevent margin collapse
    text-align: justify;
    p { margin: 0 0 0 0; }
    h3 { margin: 18px 0 0 0; }
    a { color: $activeMenu_txtColor }
    img {
        width: 100px;
        height: auto;
        margin: 0 25px 0 0;
        float: left;
    }
}
// END - 'Partner page' formatting



// ----------------------------------
// START - 'Contact page' formatting:
// ----------------------------------
div#leftCol {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-size: 0.8em;
    justify-content: space-between;
}

div#middleCol, div#rightCol {
    float: left;
    box-sizing: border-box;
    font-size:  0.8em;
}

div#middleCol {
    width: 30%;
}

div#rightCol {
    width: 70%;
}

div.staffProfile {
    background: #DDCCB2;
    padding: 5px 10px;
    margin: 15px 0;
    overflow: hidden;

    .staffPhoto img {
        float: left;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid #ccc;
        display: inline-block;
    }
    .staffContactInfo {
        width: 70%;
        float: left;
        padding: 0 0 0 3%;
        p { margin: 0; }
        a { color: $activeMenu_txtColor; }
    }
}

div.socialMedia img { margin-right: 10px;}

.contactPerson, .staffProfile {
    flex: 0 0 32%;
}

.contactPerson {
    background-color: #E8D9C2;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
        float: left;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid #ccc;
    }
    p { margin: 5px 0 0; }
    h3 { margin: 0; }
    a {
        display: flex;
        justify-content: right;
        align-items: center;
        img { margin-right: 5px !important; }

    }
}

// END - 'Contact page' formatting

.clearfix { clear: both; }
.inactiveTxt { color: #808080; }

div#textContainer div.cms_plugin {
    background: #EBDECB;
    margin: 35px 0 0 0;
    padding: 15px;
    display: block;
}


// tree list filter formatting
#treeList {
    width: 65%;
    float: left;
}

#treeFilters {
    width: 30%;
    float: left;
    background: #E8D9C2;
    min-height: inherit;
}

#treeSearchForm {
    input#id_tree_id,
    select#id_species { width: 96%; }

    select#id_species option { width: auto; }
    ul#id_size { list-style-type: none; margin: 0; padding-left: 0; }
    p { margin: 1px; line-height: 29px; }
    #id_size0, #id_size1 { width: 50px; height: 15px; }
    label { line-height: 29px; }

    input [type='checkbox'],
    input [type='radio']{
      box-shadow: none;
      padding: 0;
      margin: 0 3px 0 0;
      vertical-align: middle;
    }
    legend { color: $activeMenu_txtColor; }
    fieldset { position: relative; }

    .activeFilter { font-weight: bold; }

    .clear {
        background: #998F7F;
        border-radius: 3px;
        padding: 2px 6px;
        position: absolute;
        right: 3px;
        font-size: 0.7em;
        cursor: pointer;
    }

}



.choice .selectable option { color: black; }
.empty, .inactive { color: gray; }
.selectable { color: black; }

.treeItemDetails {
    position: relative;
    margin: 12px 5px;
    // padding: 6px;
    padding: 0 12px 6px;
    // border: 1px solid #aea391;
    background-color: rgb(235, 222, 203);
    border: 1px solid #c1b29c;
    border-radius: 3px;
    font-size: 14px;
    font-style: italic;

    li {
        margin-left: 16px;
        font-size: 14px;
        font-style: normal;
        line-height: 1.6em;
    }

    label {
        margin-left: 20px;
        width: auto;
    }

    span {
        margin: 0 0 0 10px;
        display: inline-block;
        width: 2em;
        // text-align: center;
        vertical-align: bottom;
    }

    .actionButtons {
        text-align: right;
        list-style-type: none;

        a {
            margin-left: 15px;
            font-size: 0.9em;
        }

        a:first-child { margin-left: 0;}
    }
}

#searchBy {
    padding: 15px;
    font-size: 0.9em;
}


.treeDetail {
    display: block;
    padding: 30px 0;

    ul {
        margin: 0 auto;
    }

    li {
        list-style-type: none;
    }

    span {
        display: inline-block;
        border: 1px solid #aea391;
        padding: 5px;
        width: 15em;
    }

    table {
        border-collapse: collapse;
        margin: 2em 0;
        width: 100%;
        font-size: 0.9em;
    }

    tr.header {
        font-weight: bold;
        background-color: #aea391;
        padding: 10px !important;
    }

    tr.simpleRow:hover {
        background-color: rgba(174, 163, 145, 0.1);
    }

    .empty-row > td {
        border-left: 0;
        border-right: 0;
        height: 2em;
    }

    td {
        padding: 5px 10px !important;
        border: 1px solid #aea391;
    }

    img {
        width: auto;
        height: auto;
        max-height: 500px;
        margin: 0 auto;
        display: block;
        // box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
    }

    .treePhoto {
        position: relative;
        // background-color: #aea391;
        background-color: #565047;
        padding: 5px;
    }

    .signature {
        position: absolute;
        right: 60px;
        bottom: 0;
    }

    .treeDetails {
        margin: 0 0 30px 0;
        padding: 0 0 0 16px;
    }

    .treeByVisitor { margin: 0 9px 7px 0; }

    .actionButtons { margin: 2em 0 0 0; }

    .gallery {
        position: relative;
        overflow: auto;
        background-color: #565047;
        padding: 0 5px 5px 5px;
    }

    #arrow-left { float: left; width: 5%; }
    #arrow-rigth { float: right; width: 5%; text-align: right; }

    #arrow-left>i, #arrow-rigth>i { line-height: 1.9em; color: #ccc; display: block; cursor: pointer; }
    #arrow-left>i:hover, #arrow-rigth>i:hover { color: $activeMenu_txtColor; }

    #gallery-container {
        display: flex;
        justify-content: left;
        overflow: hidden;
        // background-color: #38342e;
        float: left;
        max-width: 90%;
    }

    .gallery-item {
        position: relative;
        margin-right: 5px;
        border: 1px solid #999;
    }

    .gallery-item img {
        height: 60px;
        min-width: 70px;
        width: auto;
        // opacity: 0.5;
    }

    .gallery-item img:hover { opacity: 0.5;}

    .gallery-item .delete-btn {
        position: absolute;
        bottom: 0;
        right: 2px;
        border: none;
        color: #ccc;
        margin: 0;
        font-size: 1.2em;
    }

    .gallery-item .delete-btn:hover { color: #fff; }

    .gallery-item .approve-btn {
        position: absolute;
        bottom: 0px;
        left: 2px;

        button {
            font-size: 1.2em;
            padding: 0;
            color: #ccc;
            background-color: rgba(254, 254, 254, 0);
            border: none;
        }

        button:hover { color: #fff; }
    }
    .inactive { color: #565047!important; }
    .img-upload-form { margin-top: 25px; }
    .image-owner { color: #ccc; font-size: 0.8em; text-align: center; margin: 8px 0 5px 0; }
}

img.desaturate { -webkit-filter: grayscale(100%); filter: grayscale(100%); }

#listTreeSpecies {
    min-width: 50%;
    margin-top: 30px;
    td {
        a {
            text-align:-moz-center;
            text-align:-webkit-center;
        }
    }
    #species { text-align: left; width: 80%; }
    tr:hover { background: rgba(100,100,100, 0.3); }
}

.unverified {
    font-size: 0.7em;
    color: $activeMenu_txtColor;
    display: inline-block;
    border: 1px solid gray;
    padding: 0 3px;
    border-radius: 5px;
}
.verified {
    font-size: 0.7em;
    color: green;
    display: inline-block;
    border: 1px solid gray;
    padding: 0 3px;
    border-radius: 5px;
}

.primary {
    font-size: 0.7em;
    display: inline-block;
    border: 1px solid gray;
    padding: 0 3px;
    border-radius: 5px;
}
.hidden { visibility: hidden; }

.icon-down::before {
    font-size: 1em;
    content: "+";
    color: red;
}

#accordion img { max-width: 100%; }

h3.accordionHeader {
    border: 1px solid #C5B9A5;
    background: #E8D9C2;
    &:hover { border: 1px solid gray; }
}

hr {
    height: 1px;
    border: 0;
    box-shadow: inset 0 1px 1px -1px rgba(0,0,0,0.5);
    margin-top: 1em;
    margin-bottom: 1em;
}

.newsInShort {
    padding: 0 15px;
}

.newsItemsInShort {
    list-style-type: none;
    font-size: 0.9em;
    margin: 15px 0;
    p { margin: 2px; }
    .newContent { font-style: italic; }
}

.newsList {
    font-size: 0.9em;
    margin-bottom: 15px;

    p { margin: 0; }
    .newsTitleSmall { font-weight: bold; }
    .newsMoreLink a { font-style: italic; color: $activeMenu_txtColor; }
}
.newsTitleNormal { margin-top: 0; }
.author { font-style: italic; font-size: 0.9em; }

.entry-previous {
    margin: 0px;
    font-size: 12px;
}
.story {
    li {
        list-style-type: none;
    }
    li a { color: $activeMenu_txtColor; }
}

.right {
    float: right;
}

.edit {
    display: block;
    height: 16px;
    width: 16px;
    background: url('../img/icon-edit_16x16.png') no-repeat center center;
    padding: 3px;
    margin: 3px;
}
.delete {
    display: block;
    height: 16px;
    width: 16px;
    background: url('../img/icon-delete_16x16.png') no-repeat center center;
    padding: 3px;
    margin: 3px;
}
.approve {
    display: block;
    height: 16px;
    width: 16px;
    background: url('../img/icon-approve_16x16.png') no-repeat center center;
    padding: 3px;
    margin: 3px;
}

.instructions {
    display: inline-block;
    height: 28px;
    width: 28px;
    background: url('../img/icon_more-info.png') no-repeat center center;
    margin: 15px;
}
.more {
    display: inline-block;
    height: 28px;
    width: 28px;
    background: url('../img/icon-more.png') no-repeat center center;
    margin: 9px 25px 3px 3px;
}

.edit:hover,
.delete:hover,
.approve:hover,
.instructions:hover,
.more:hover {
        -webkit-filter: invert(.8);
        filter: invert(.8);
}

// jquery UI dialog box styling
.ui-widget {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 0.9em!important;
}
.ui-dialog-titlebar { font-weight: normal!important; }
.ui-widget-header {
    background: #AEA391!important;
    border: 1px solid #aaaaaa;
    color: #222222;
    font-weight: bold;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl { border-radius: 0px!important; }

.ui-button {
    box-shadow: 1px 1px 1px #AEA391!important;
    border: 1px solid #8F8678!important;
    background: #AEA391!important;
    display: inline-block!important;
    padding: 3px 8px;
    font-size:  0.9em;
    margin: 5px 2px;
}

.ui-button:hover {
  background: #998F7F!important;
  color: $activeMenu_txtColor!important;
}

.ui-widget-content a {
    color: $activeMenu_txtColor!important;
}

// form label formatting
.signup, .changePassword, .userLogin {
    label {
        width: 40%;
        text-align: right;
        display: inline-block;
    }
}

.readonly {
    pointer-events: none;
    opacity: 0.5;
}

#group_form {
    .fieldWrapper {
        box-sizing: border-box;
        padding: 0 1rem;
        width: 100%;

        label {
            box-sizing: border-box;
            display: inline-block;
            text-align: right;
            width: 40%;
        }

        input {
            box-sizing: border-box;
            height: 2.2em;
            max-width: none;
            padding: 5px 10px;
            width: 58%;
        }

        textarea {
            box-sizing: border-box;
            max-width: none;
            padding: 5px 10px;
            width: 58%;
            height: 5em;
            font-size: 0.8em;
        }

        div {
            text-align: right;
            padding-right: 2%;
        }

        input:disabled,
        textarea:disabled {
            color: #939596;
        }

        input::placeholder,
        textarea::placeholder {
            font-family: sans-serif;
        }

        .approved {
            display: inline-block;
            border: 1px solid rgba(0, 128, 0, 0.35);
            border-radius: 1px;
            padding: 2px 5px;
            background: rgb(210, 228, 200);
            color: green;
            font-size: 0.8em;
        }
        .notApproved {
            @extend .approved;
            color: red;
            border: 1px solid rgba(209, 5, 12, 0.29);
            background: rgba(209, 5, 12, 0.21);
        }

    }
}

.publisingInfo {
    font-style: italic;
    font-size: 0.9em;
}

.adoption_history {
    font-size: 0.9em;
    margin: 3em 0 0 0;

    .container {
        margin: 20px 0;
        display: flex;
        align-items: center;
    }

    .action-icon {
        width: 1.2em;
    }

    .info-txt {
        border-left: 3px solid #aea391;
        padding-left: 10px;
        margin-left: 10px;
    }
}

span.adopting-group {
    width: auto;
    padding: 1px 5px;
    margin: 0 2px;
    background-color: rgb(235, 222, 203);
    border-radius: 2px;
    font-size: 0.8em;
    border: 1px solid #c1b29c;
    color: #998f7f;
}

.adoption-container {
    display: flex;
    align-items: stretch;

    span { margin: 0 0 10px 0; }

    div {
        width: 50%;
        margin-left: 5px;
        padding-left: 5px;
        text-align: inherit;
    }

    li {
        list-style-type: none;
        margin: 0;
        font-size: 0.9em;
    }
}

.form-block {
    width: 100%;

    .field-wrapper {
        width: 100%;
        margin: 20px 0;

        p {
            text-align: right;
            padding-right: 10%;
            margin: 0;
        }
    }

    .simple-label {
        width: 40%;
        display: inline-block;
        text-align: right;
    }

    input {
        width: 50%;
        display: inline-block;
        height: 3em;
        box-sizing: border-box;
        padding: 3px 10px;
    }
}

.form-block-half {
    width: 50%;
    float: left;
}


// ------------------------------
//-- Worksheet                 --
// ------------------------------

.field-wrapper {
    margin: 2em 0;
    background: #ebdecb;
    padding: 1em;
    border: 1px solid #c1b29c;

    label {
        font-weight: bold;
    }

    input {
        vertical-align: middle;
    }

    .help-text {
      padding: 5px;
      font-size: 0.8em;
    }

    .asterisk { color: $anchor_hover_txtColor; }

    li { list-style-type: none; }

    textarea { height: auto; width: 100%; }

    ul {
        padding: 0;
    }
}

.notification {
    font-size: small;
    color: red;
    margin: 0.3em 1em;
}


details {
    margin-bottom: 1em;
    padding: 1em;
    background: #e8d9c2;
}

summary {
    cursor: pointer;
}

.ws-img {
    margin: 1em 0;
}

.bga-logo {
    border-top: 1px solid #c1b29c;
    padding: 20px 0 0 0;
    margin: 20px 0 0 0;

    p {
        text-align: center;
    }

    img {
        margin: 0 auto;
        display: block;
    }
}

// when the django-cms toolbar is present our menu is not visible
// because both have fixed positions. The cms toolbar's height is 46px
// so we need to push down our menu with 46px to be visible.
.cms-toolbar-expanded body .nav_wrapper {
    top: 46px;
}

// top tree statistics style
.leftColumn {
    float: left;
    display: flex;
    flex-wrap: wrap;
    .imgDiv {
        margin: 1em 1em 0 0;
        float: left;
    }

    .treeItemDetails {
        flex: 1 0 45%;
        h2 { border-bottom: 1px solid gray; }
    }
}

@media (max-width: 768px) {
    .leftColumn .treeItemDetails {
        flex-basis: 90%; /* Switch to single column layout on smaller screens */
    }
}

// Terms and Conditions formatting
.toc-container {
    text-align: justify;
}

#tc-terms-form {
    text-align: center;

    input {
        padding: 6px 25px;
        border-radius: 2px;
        box-shadow: 1px 1px 1px #AEA391;
        border: 1px solid #8F8678;
        background: #AEA391;
        &:hover {
            background: #998F7F;
            color: #d1050c;
            cursor: pointer;
        }
    }

}

#termsandconditions {
    background-color: #ddccb2 !important;
    color: #565655 !important;
    width: 50% !important;
    margin: 0 auto;
    #toc-header {
        background-color: gray !important;
        background-color: #998F7F !important;
        color: #565655 !important;
        h6, a {
            color: #565655 !important;
        }
    }
    #toc-content {
        display: flex;
        justify-content: space-between;

        #toc-footer {
            display: flex;
            align-items: center;
            width: auto !important;
            .toc-accept-all-btn {
                color: #565655 !important;
                box-shadow: 1px 1px 1px #AEA391;
                padding: 6px 25px !important;
                border: 1px solid #8F8678;
                background: #AEA391;
                border-radius: 2px !important;
                text-align:center;
            }
        }
    }
}

#userLogin .termCheckbox {
    display: none;
    width: 100%;
    text-align: justify;
}

// Modal formatting on signup page
/* Modal styles */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal content styles */
.modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 800px; /* Limit the maximum width of the modal content */
}

/* Modal header styles */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.modal-header h1 {
    background: none;
}

.modal-header .close {
    padding: 5px;
    font-size: 30px;
    cursor: pointer;
    float: right;
}

/* Modal body styles */
.modal-body {
    padding: 10px 0;
    max-height: 60vh; /* Limit the height of the modal body */
    overflow-y: auto; /* Enable vertical scrolling */
}

/* Modal footer styles */
.modal-footer {
    display: flex;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    justify-content: center;
    align-items: center;
}
.modal-content .modal-footer label {
    width: auto;
}

/* Style for accordion header */
.accordion-header {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    font-size: 1.5em;
    font-weight: bolder;
}

/* Style for active accordion header */
.accordion-header.active {
    background-color: #ddd;
}

/* Style for accordion content */
.accordion-content {
    display: none;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

/* Style for active accordion content */
.accordion-item.active .accordion-content {
    display: block;
}

.fieldWrapper.fullWidth {
    label {
        width: 100%;
        text-align: left;
    }
}

#group_form {
    #informedConsentModal {
        div {
            text-align: left;
        }
    }
    .modal-footer {
        input, label { width: unset; }
    }
    .fieldWrapper.fullWidth {
        label {
            width: 100%;
            text-align: left;
        }
        input { width: unset; }
    }
}


// cookie window

#cc_div #cm, #s-inr #s-hdr, #s-inr #s-bl, #s-inr #s-bns, .desc.b-acc { /* cookie banner, modal: header, body, footer (buttons) */
    background: #E8D9C2;
}

#cm #c-p-bn,
#s-bns #s-all-bn {
    background-color: #998F7F;
    color: #d1050c;
}

#cm #c-s-bn, #s-sv-bn, #s-c-bn,
#s-cnt button.b-tl.exp, #s-inr #s-bl tbody tr:hover {
    background: #ddccb2;
}

#s-inr {
    #s-hdr, #s-bl .desc.b-acc thead tr {
        border-bottom: 2px solid #ddccb2;
    }
    #s-bns {
        border-top: 2px solid #ddccb2;
    }
    .desc.b-acc {
        border: 2px solid #ddccb2;
        &:hover {
            background: #E8D9C2;
        }
    }
}


#s-cnt .b-bn .b-tl.exp:active, #s-cnt .b-bn .b-tl.exp:hover,
#s-inr #s-bl .c-bl:hover, #s-c-bnc #s-c-bn:hover, #s-cnt #s-bns #s-sv-bn:hover {
    background: #998F7F;
}

#s-cnt #s-bl .c-bl {
    border: 1px solid #ddccb2;
    &:first-child {
        border: none;
        &:hover {
            pointer-events: none;
        }
    }
}
