@import 'constants';

.entry-last-update {
    font-size: 0.8em;
    font-style: italic;
}

.content {
    width: 68%;
    // height: 500px;
    float: left;
    background: $section_bgColor;
    padding: 15px;
    box-sizing: border-box;
    text-align: justify;

    // overflow-y: scroll;
    &::-webkit-scrollbar { width: 8px; }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
}

#sidebar {
    width: 30%;
    float: right;
    background: $form_bgColor;
    // border: 1px solid red;

    // overflow-y: scroll;
    &::-webkit-scrollbar { width: 8px; }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
}

p.continue-reading {
    display: inline;
    a:link, a:visited { color: $activeMenu_txtColor; }
}

div#title {
    position: relative;
    h1    { }
    p     { margin: 0; }
    form  {
        position: absolute;
        top: 0;
        right: 15px;
        padding: 7px 0;
    }
    input { margin: 5px 0;}
}

.entry-header {
   white-space: normal;
}

.entry-widgets {
    display: block;

}
.entry-previous {
    float: left;
    width: 48%;
    margin: 20px 0 0 0;
    a:link, a:visited { color: $activeMenu_txtColor; }
    p { font-size: 0.8em; }
}
.entry-next {
    float: right;
    width: 48%;
    text-align: right;
    margin: 20px 0 0 0;
    a:link, a:visited { color: $activeMenu_txtColor; }
    p { font-size: 0.8em; }
}

div.entry-image img {
    max-width: 90%;
}

div.entry-body div.entry-content span.plugin_picture img {
    max-width: 30%;
    height: auto;
    float: left;
    margin: 0 30px 30px 0;
    border: 1px solid white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}