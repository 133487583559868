@import 'constants';

//=========================================
//=   CONTAINER FOR INPUT FILEDS          =
//=========================================
.fieldWrapper {
    position: relative;
    margin: 5px 0;

    li { list-style-type:  none; }

    .helptext {
        font-size: 0.8em;
        text-align: left;
        font-weight: normal;
        font-style: italic;
    }

    .moreInfoSign {
        background: url('/static/img/icon_question-mark_25x25.png') no-repeat center center;
        width: 29px;
        height: 29px;
        float: left;
        margin-right: 10px;
    }

    .infoPopup {
        position: absolute;
        z-index: 100;
        top: 30px; left: 30px;
        min-width: 120px;
        padding: 5px;
        font-size: 0.8em;
        text-align: left;
        display: none;
        background: #F7F7BE;
        border-radius: 3px;
        -webkit-box-shadow: 1px 1px 3px 1px rgba(50, 50, 50, 0.75);
        -moz-box-shadow:    1px 1px 3px 1px rgba(50, 50, 50, 0.75);
        box-shadow:         1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    }

    #treeSize {
        position: absolute;
        margin: 0 auto; left: 0; right: 0;
        min-height: 150px;
        min-width: 150px;
        text-align: left;
        display: none;
        background: #DDCCB2;
        -webkit-box-shadow: 1px 1px 3px 1px rgba(50, 50, 50, 0.75);
        -moz-box-shadow:    1px 1px 3px 1px rgba(50, 50, 50, 0.75);
        box-shadow:         1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    }

    // input { width: 50%; }
}

//=========================================
//=   General settings                    =
//=========================================
sup { color: $activeMenu_txtColor; }

.fieldsetOrderNo {
        font-size: 1em;
        font-weight: bold;
}

// used for form validation error msg formatting
label.error {
    display: block;
    width: auto!important;
    height: auto;
    color: $activeMenu_txtColor;
    margin: 0 0 0 24px;
    font-size: 12px;
    text-align: right;
}

input.error {
    background: #fff url(/static/img/invalid.png) no-repeat 98% center;
    box-shadow: 0 0 5px #d45252;
    border-color: $activeMenu_txtColor;
}
input[type="file"].error {
    @extend input.error;
    width: auto;
}

select.error {
    @extend input.error;
    background: #fff url(/static/img/invalid.png) no-repeat 90% center!important;
}

.errorlist ul { margin-top: -20px; }
.errorlist li { color: red; font-size: 0.8em; }



//========================================
//=   THE 5 CATEGORY OF THE FORM:
//=    - #mandatoryFields
//=    - #treeCondition
//=    - #humanImpact
//=    - #treeLocation
//=    - #treeImportance
//=========================================
//=  +  User sign-up form                 =
//=========================================

.container { display: table; width: 100%; height: 100%; margin-bottom: 30px;}
.containerRow { display: table-row; height: auto; }
.containerColumn { display: table-cell; height: 100%; }

.mandatoryFields,
.mandatoryFieldsTree,
.additionalUserInfo,
.treeCondition,
.humanImpact,
.treeLocation,
.treeImportance {
    float: left;
    width: 50%;
    label { line-height: 29px; }
}

.mandatoryFieldsTree {
    width: 49%;
    margin-right: 1%;
    input, select { width: 45%; float: none; padding: 6px 3px;}
    .mandatoryLabel { width: 170px; line-height: 29px; display: inline-flex; }
    .narrow { width: 90px; }
    .wide { display: block; width: auto; margin-left: 39px; }
    .treeFormImg { display: block; margin-left: 39px; width: auto; font-size: 0.8em; }
    .fieldWrapper { margin: 16px 0; }
}

.mandatoryFieldsTree > label { width: 35%; }

.treeCondition { 
    label { width: auto; }
    textarea {
        height: 3em;
        width: 98%;
    }
}

.humanImpact {
    width: 49%;
    margin-right: 1%;
    margin-top: 30px;

    label {
        width: auto;
    }
    textarea {
        height: 7em;
        width: 98%;
    }
}

.treeLocation {
    margin-top: 30px;
    li {
        // width: 150px;
    }
    label {
        width: auto;
        min-width: 100px;
        display: inline-block;
        vertical-align: top;
    }
    textarea {
        height: 23px;
        width: 65%;
        vertical-align: top;
    }
    ul { padding: 0; }
    #id_location {
        display: block;
        margin: 0 0 0 103px;
    }
    #id_private_public {
        margin: 0 0 0 103px;
    }
    #id_other { margin-left: -50px; }
    .landscapeOpts { width: 50%; float: left; }
    .grouppingOpts { margin: 0 0 0 100px; }
}

.treeImportance {
    margin-top: 30px;
    width: 100%;
    textarea { height: 7em; width: 99%; }
}

.groupDescription {
    textarea { 
        height: 7em;
        width: 50%;
    }
}

.required_notification {
    color: $activeMenu_txtColor;
    // margin:5px 0 0 0;
    // display:inline;
    float:right;
    font-size: 0.6em;
    font-weight: normal;
}

input, select, textarea {
    border:1px solid #aaa;
    box-shadow: 0px 0px 3px #fff, 0 10px 15px #fcf7ef inset;
    border-radius:2px;
    padding: 2px 3px;
    height: 23px;
    // box model used by the input/textarea/select elements so that they all behave the same way
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    // ------
    -moz-transition: padding .25s;
    -webkit-transition: padding .25s;
    -o-transition: padding .25s;
    transition: padding .25s;
    -moz-placeholder { color: #666; }
    -webkit-input-placeholder { color: #666; }
}

input:focus, select:focus, textarea:focus {
    background: #fff;
    border: 1px solid #555;
    box-shadow: 0 0 3px #aaa;
}

input[readonly],select[readonly], textarea[readonly] {
    background-color: #eeede6;
    color: gray;
    cursor: not-allowed;
}

input[type="checkbox"] {
    box-shadow: none;
    padding: 0;
    vertical-align: bottom;
    *overflow: hidden;
}

input[type="radio"] {
    box-shadow: none;
    padding: 0;
    margin:0;
    vertical-align: text-bottom;
}

input[type="file"] {
    box-shadow: none;
    border: none;
    width: auto;
    padding: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type="number"] {
    -moz-appearance:textfield;
}

fieldset {
    // height: 100%;
    // margin: 0 10px 0 0;
    border: 1px solid #998F7F;
}

legend {
    padding: 3px 15px;
    border: none;
    font-weight: bold;
}

.captcha { margin-top: 25px; }
.paddingTop { padding-top: 10px; }
.italic { font-style: italic; font-size: 0.7em; }
.right { float: right; }
.noMargin { margin: 0 0 0 25px; }
.grouppingOpts { margin-left: 53px; }
