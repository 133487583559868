// Site color settings & box sizes
@import 'constants';

// used on tree map
#mapCanvas {
    height: auto;
    min-height: 600px;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    margin: 0 0 25px 0;
    z-index: 0;
}

// used on group signup form
.mapCanvas {
    height: auto;
    min-height: 435px;
    border: 1px solid #aaa;
    margin: 10px 0 25px 0;
    z-index: 0;
}


#informationLinks {
    position: relative;
    width: $wrapper_width;
    margin: 0 auto;
    background: #F4F3F0;
    box-sizing: border-box;
    border: 1px solid #000;
    border-bottom: 1px solid #ddd;
    height: 60px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

    .controls {
        float: left;
        margin-top: 16px;
        border: 1px solid transparent;
        border-radius: 2px 0 0 2px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        height: 32px;
        outline: none;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    }
}


#pac-input {
    display: table-cell;
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
    margin-bottom: 6px;
    border: 1px solid #ddd;
    box-shadow: none;
}

#pac-input:focus {
    border-color: #4d90fe;
}

.pac-container {
    font-family: Roboto;
}

#type-selector {
    color: #fff;
    background-color: #4d90fe;
    padding: 5px 11px 0px 11px;
}

#type-selector label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}


.mapBtn {
    display: inline-block;
    height: 58px;
    float: right;
}

#btnEnterFullScreen {
    background: url("/static/img/fullscreen.png") no-repeat center;
    width: 28px;
    height: 28px;
    border: 0px solid gray;
    border-radius: 3px;
    padding: 0;
    margin: 15px;
    &:hover {
        cursor: pointer;
        -webkit-filter: invert(.8);
        filter: invert(.8);
    }
}

#btnExitFullScreen {
    position: fixed; top: 15px; right: 5px;
    z-index: 1000;
    background: url("/static/img/fullscreen_exit.png") no-repeat white center;
    width: 34px;
    height: 34px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    border: 1px solid gray;
    margin: 1px 0 0 0; padding: 2px;
    &:hover {
        border: 1px solid #4D90FE;
        cursor: pointer;
    }
}

.whereAmI {
    direction: ltr;
    overflow: hidden;
    text-align: center;
    position: relative;
    color: rgb(86, 86, 86);
    font-family: Roboto,Arial,sans-serif;
    -moz-user-select: none;
    font-size: 11px;
    background-color: rgb(255, 255, 255);
    padding: 8px;
    border-radius: 2px;
    background-clip: padding-box;
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.3);
    border-left: 0px none;
    min-width: 37px;
    cursor: pointer;
}

#infoWindow {
    max-width: 240px;
    p { margin: 0; }
    button {
        margin-top: 10px;
        padding: 6px;
        font-size: 12px;
    }
}

.treeRegBtn {
    background: greenyellow;
    border: 1px solid #666;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    cursor: pointer;
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
}

.treeRegBtn:hover {
    background: yellowgreen;
    color: $activeMenu_txtColor;
}
.treeRegBtn:active { background: gold; }

#continueTreeRegistering,
#cancelTreeRegistering {
    margin: 5px 10px 5px 0;
    padding: 5px 10px;
    height: 35px;
}


#loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    color: white;
}
