@import 'constants';

body { overflow-x:hidden; }

// nav { width: $wrapper_width; }

#topmenu { /* center the menu  */
    display: table;
    width: 100%;
}

.nav_wrapper {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 53px;
  max-width: $wrapper_width;
  margin: 0 auto;
  left: 0; right: 0;
  transition: top .5s ease-out;
  background: rgba(254, 254, 254, 0.8);
  z-index: 50;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.scroll { top: -90px; }

.no-scroll {
  top: 0;
  z-index: 9999;
}

.spinner-master input[type=checkbox] { display: none; }
.spinner-master { height : 0; }

.menu {
  width: 100%;
  height: auto;
  background: rgba(254, 254, 254, 0.5);
  transition: all 0.3s ease;

  ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        position: relative;
        display: inline-block;
    }

    & > li > ul.sub_menu {
        min-width: 10em;
        padding: 4px 0;
        background-color: rgba(254, 254, 254, 0.2);
        border: 1px solid #fff;
    }

    ul li { padding: 0px; }

    & > ul > li { display: inline-block; }

    ul li a {
        display: block;
        text-decoration: none;
        color: #565656;
        // font-size: 14px;
    }

    ul li a:hover {
        background: rgba(254, 254, 254, 1);
        color: #d1050c;
    }

    ul li:hover > a {
        background: rgba(254, 254, 254, 1);
        color: #d1050c;
    }

    ul li > a { padding: 15px; }

    ul ul {
        display: none;
        position: absolute;
        top: 100%;
        min-width: 160px;
        background: rgba(254, 254, 254, 0.2);
    }

    ul li:hover > ul { display: block; }

    ul ul > li { position: relative; }

    ul ul > li a {
        padding: 10px 15px;
        height: auto;
        background: rgba(254, 254, 254, 0.6);
    }

    ul ul > li a:hover {
        background: rgba(254, 254, 254, 1);
        color: #d1050c;
    }

    ul ul ul {
        position: absolute;
        left: 100%;
        top: 0;
    }
}

#menuSwitcherBtnContainer {
    position: fixed;
    width: $wrapper_width;
    margin: 0 auto; left: 0; right:0;
    top: $navMenu_height;
    color: $activeMenu_txtColor;
    z-index: 15;
}

#menuSwitcherBtn {
    position: relative;
    float: left;
    z-index:  15;
    cursor: pointer;
    // width: 100px;
    padding: 0 8px;
    height: 16px;
    background: white;
    text-align: center;
    font-size: 0.7em;
    -webkit-box-shadow: 1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    box-shadow:         1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    &:hover { color: red; }
}

#triangleTopleftMenu {
    position: relative;
    float: left;
    z-index:  15;
    cursor: pointer;

    width: 0;
    height: 0;
    border-top: 16px solid white;
    border-right: 16px solid transparent;
}

#triangleTopRightMenu {
    position: relative;
    float: right;
    z-index:  20;
    cursor: pointer;

    width: 0;
    height: 0;
    border-top: 16px solid white;
    border-left: 16px solid transparent;
}

#loggedInUser {
    position: relative;
    float: right;
    z-index:  20;
    background: white;
    text-align: center;
    font-size: 0.7em;
    color: gray;
    padding: 0 5px;

    -webkit-box-shadow: 1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    box-shadow:         1px 1px 3px 1px rgba(50, 50, 50, 0.75);
}

#myAccount {
    position: absolute;
    right: 0;
    z-index:  15;
        background: rgba(254, 254, 254, 0.8);
    text-align: center;
        font-size: 0.9em;
    color: gray;
    width: 150px;
        padding: 0;

    -webkit-box-shadow: 1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    box-shadow:         1px 1px 3px 1px rgba(50, 50, 50, 0.75);
    li {
        padding: 0;
        list-style-type: none;
        text-align: left;
    }
    li:hover {
        background: rgba(240, 240, 240, 1);
    }
    a {
        display: block;
        width: inherit;
        padding: 5px;
    }
}

#avatar {
    position: absolute; top: 0; right: 0;
    // height: 50px;
    height: 100%;
    // width: 52px;
    // padding-left: 30px;
    // background: url(/static/img/icon-user.png) no-repeat center;
    font-size: 14px;
}

li#avatar, li#lng, li#avatar li, li#avatar li:hover { list-style-type: none; }
li#avatar:hover  { cursor: pointer; }
li#avatar:hover  > ul { display: block; right: 0; }

#avatar-menu {
    display: flex;
    height: inherit;
    box-sizing: border-box;
    right: 0;
    // display: block;
    text-align: center;
    line-height: 12px;
    color: #565656;
    padding: 0 15px;

    &:hover {
        color: crimson;
        background: #fff;
    }
}



#lngChooser {
    position: absolute; top: 0; right: 150px;
    // height: 50px;
    padding-left: 30px;
    margin: 0;
    font-size: 14px;
    li { display: inline; }
}

.sub-menu {
    display :none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    // right: 0;
}

.sub-menu li {
    background: rgba(240, 240, 240, 0.8);
    min-width: 160px;
}

.sub-menu li:hover {
    background: rgba(240, 240, 240, 1);
    color: $activeMenu_txtColor;
}

.sub-menu li a {
    color: $fontColor;
    display: block;
    padding: 10px 15px;
}

.sub-menu li a:hover {
    color: $activeMenu_txtColor;
}

.pagination {
    font-size: 0.875em;
    text-decoration: none;

    .prototype {
        display: block;
        float: left;
        padding: 3px 6px;
        text-align: center;
        margin-bottom: 2px;
        margin: 0 2px;
        border: 1px solid #998f7f;
    }
    .page {
        @extend .prototype;
        background: #AEA391;
    }

    .current,
    .disabled,
    .prev,
    .next {
        @extend .prototype;
        background: #998F7F;
    }

    .ellipsis { @extend .prototype; }

    span.current {
        @extend .prototype;
        background: #AEA391;
    }

    .page:hover {
        background: #998F7F;
    }

    a:first-of-type, a.current, a:last-of-type { display: block;}
}

#backToTop {
    display: none;
    position: fixed;
    right: 5px;
    bottom: 65px;
    width: 40px;
    height: 40px;
    background: url(/static/img/back-to-top.png) no-repeat center;
    border: 1px solid #fff;
    box-shadow: 0 0 2px 2px rgba(120, 120, 120, 0.2);
    z-index: 10;
    padding: 0;
    img:hover {
        -webkit-filter: invert(.8);
        filter: invert(.8);
    }
}
